.chart-table {
	border:1px solid black;
}

.chart-table th, .chart-table td {
	border: 1px solid #dddddd;
	text-align: left;
	padding: 8px;
}

.chart-table th {
	border: 1px solid #dddddd;
}
