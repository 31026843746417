a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    font-size:xx-large;
    color:crimson
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


/* Custom styles for the NavbarBrand component */
.custom-navbar-brand {
    font-size: 24px; /* Change the font size */
    font-weight: bold; /* Apply bold font weight */
    padding-left: 20px;
}